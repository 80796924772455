import axios from "axios";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingView from "./components/LoadingView";
import { Switchs } from "./routes/Switchs";
import setupAxios from "./setup/SetupAxios";

function App() {
  setupAxios(axios);

  return (
    <Suspense fallback={<LoadingView />}>
      <BrowserRouter>
        <Switchs />
      </BrowserRouter>
      <ToastContainer />
    </Suspense>
  );
}

export default App;
