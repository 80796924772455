import React from "react";
import { Button } from "@headlessui/react";

const CloseButton = ({ onClick, classNames }) => {
  return (
    <Button
      onClick={onClick}
      type="button"
      className={`absolute top-2 right-2 bg-red-500 hover:bg-red-700 text-white font-bold h-6 w-6 rounded-full focus:outline-none focus:ring-2 focus:ring-red-600 ${classNames}`}
    >
      <div className="w-full h-full relative">
        <span
          className="absolute"
          style={{ top: "-3px", right: "5px", fontSize: "19px" }}
        >
          &times;
        </span>
      </div>
    </Button>
  );
};

export default CloseButton;
