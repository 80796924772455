import React from "react";
import PropTypes from "prop-types";
import ProductItem from "./ProductItem";
import CloseButton from "../../../layout/components/buttons/CloseButton";
import { useProcessStore } from "../../../Store";
import { useShallow } from "zustand/shallow";
import StoreCategoryItemDialog from "../StoreCategoryItemDialog";
import { Button } from "@headlessui/react";

const CategoryItem = ({ category }) => {
  const [selectCategory, setSelectCategory] = React.useState(null);
  const { removeCategoryByName, overwriteStore } = useProcessStore(
    useShallow((state) => ({
      removeCategoryByName: state.removeCategoryByName,
      overwriteStore: state.overwriteStore,
    }))
  );
  const removeACategory = () => {
    removeCategoryByName(category.category);
  };
  return (
    <div
      className={`border-2 m-5 ${
        category.imported && !overwriteStore
          ? "border-red-700"
          : "border-stone-500"
      } mt-8 relative`}
    >
      <div>
        <Button
          onClick={() =>
            category.importedProducts.length > 0
              ? setSelectCategory(category)
              : null
          }
          className={`font-bold ${
            category.importedProducts.length > 0 ? "cursor-pointer" : ""
          } text-orange-900 text-2xl my-2 text-center d-block w-full`}
        >
          {category.category}{" "}
          {category.importedProducts.length > 0 &&
            `(${category.importedProducts.length} products imported 👆)`}
        </Button>
        {category.imported && !overwriteStore && (
          <>
            <h2 className="text-red-500 mb-2 w-full text-center">
              Category with this name is already imported!
            </h2>
            <CloseButton onClick={removeACategory} />
          </>
        )}
      </div>
      <div className="mb-2">
        {category?.products?.map((product, index) => {
          return (
            <ProductItem
              categoryImported={category.imported}
              product={product}
              key={index}
              overwriteStore={overwriteStore}
            />
          );
        })}
      </div>
      <StoreCategoryItemDialog
        isOpen={!!selectCategory}
        setIsOpen={() => setSelectCategory(null)}
        category={selectCategory}
        overwriteStore={overwriteStore}
      />
    </div>
  );
};
CategoryItem.propTypes = {
  category: PropTypes.shape({
    category: PropTypes.string.isRequired,
    importedProducts: PropTypes.array,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
        description: PropTypes.array.isRequired,
      }).isRequired
    ),
    imported: PropTypes.bool,
  }).isRequired,
};
export default CategoryItem;
