import React from "react";
import PropTypes from "prop-types";

const ProductItem = ({ product, overwriteStore, categoryImported }) => {
  return (
    <div className="py-2 px-5">
      <div className="flex flex-row justify-between">
        <div>
          <p className={`font-bold text-xl`}>
            <span
              className={`${
                product.imported
                  ? "line-through text-gray-600"
                  : "text-gray-700"
              }`}
            >
              {" "}
              {product.name}
            </span>

            {categoryImported && (
              <span>
                {/* <label className="mx-2 text-gray-700" htmlFor="overwriteStore">
                  import
                </label> */}
                <input
                  className={`text-base mx-4 border rounded-lg cursor-pointer bg-gray-50 border-gray-300 text-gray-900 focus:outline-none`}
                  id="overwriteStore"
                  type="checkbox"
                  disabled
                  checked={product.imported}
                  onChange={() => {}}
                />
              </span>
            )}
          </p>
          <p
            className={`mt-1 ${
              product.imported ? "line-through text-gray-500" : "text-gray-600"
            }  text-md`}
          >
            {product.description?.length > 0 && product.description[0]}
          </p>
          {product.extras?.length > 0 && (
            <p className="flex flex-wrap my-2 justify-start items-center">
              Addons:
              {product.extras?.map((extra, index) => {
                const displyPrice = extra.price?.replace(/\s+/g, "");
                return (
                  <span
                    className="mx-1 bg-gray-200 p-1 px-2 rounded-full text-sm"
                    key={index}
                  >
                    {extra.name} {displyPrice ?? 0}
                  </span>
                );
              })}
            </p>
          )}
        </div>
        <div>
          <p
            className={`font-bold ${
              product.imported && !overwriteStore
                ? "line-through text-gray-600"
                : "text-gray-700"
            }  text-xl`}
          >
            {product.price}
          </p>
        </div>
      </div>
    </div>
  );
};
ProductItem.propTypes = {
  categoryImported: PropTypes.bool,
  overwriteStore: PropTypes.bool,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    description: PropTypes.arrayOf(PropTypes.string).isRequired,
    imported: PropTypes.bool,
    extras: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
      })
    ),
  }),
};
export default ProductItem;
