import React from "react";

const getclassName = (touched, error) => {
  if (touched && error) {
    return "bg-red-50 border-red-500 text-red-900";
  } else if (touched) {
    return "bg-gray-50 border-gray-300 text-gray-900";
  } else {
    return "";
  }
};
const FormikInputComponent = ({
  field,
  form: { touched, errors },
  placeholder,
  label,
  required,
  className,
  labelclassName,
  noError,
  disable,
  hint,
  type,
  ...props
}) => (
  <>
    <input
      {...field}
      {...props}
      disabled={disable}
      value={field.value ?? ""}
      placeholder={placeholder}
      className={`block w-full text-lg border p-2 rounded-lg cursor-pointer bg-gray-50 border-gray-300 text-gray-900 focus:outline-none ${getclassName(
        touched[field.name],
        errors[field.name]
      )}`}
      type={type ?? "text"}
    />
    {!noError && touched[field.name] && errors[field.name] && (
      <span className="text-sm text-red-500">
        {errors[field.name]?.toString()}
      </span>
    )}
  </>
);

export default FormikInputComponent;
