import React from "react";
import { Button } from "@headlessui/react";

const DangerButton = ({ onClick, label }) => {
  return (
    <Button
      onClick={onClick}
      className="inline-flex items-center gap-2 mb-2 rounded-md bg-rose-600  py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/2 focus:outline-none data-[hover]:bg-rose-700 data-[open]:bg-rose-700 data-[focus]:outline-1 data-[focus]:outline-white"
    >
      {label}
    </Button>
  );
};

export default DangerButton;
