import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import CloseButton from "../../layout/components/buttons/CloseButton";

const ImageDialog = ({ selectedImage, setSelectedImage }) => {
  return (
    <Dialog
      open={!!selectedImage}
      onClose={() => setSelectedImage(null)}
      className="relative z-40"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/60" />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-xl space-y-4 border bg-white p-5 relative">
          <CloseButton onClick={(e) => setSelectedImage(null)} />
          <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <TransformComponent
                  wrapperClass="h-full text-center"
                  contentClass="h-full w-full text-center"
                >
                  <img
                    className="object-contain w-full rounded-md"
                    src={selectedImage}
                    alt={`Selected item`}
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ImageDialog;
