import React from "react";
import { useProcessStore } from "../../Store";
import { useShallow } from "zustand/shallow";
import { STEP_PAGES } from "../../utils/Constants";

const FailedComponent = () => {
  const { error, setError, goToStep } = useProcessStore(
    useShallow((state) => ({
      error: state.error,
      setError: state.setError,
      goToStep: state.goToStep,
    }))
  );
  const goToImportPage = () => {
    setError(null);
    goToStep(STEP_PAGES.IMAGE_UPLOADER);
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm text-center">
        <div className="flex justify-center items-center bg-red-100 text-red-500 w-12 h-12 rounded-full mx-auto mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="{2}"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Error!</h2>
        <div className=" mb-6">
          <p className="text-gray-600">
            Something went wrong. Please try again.
          </p>
          <p className="text-red-500">{error}</p>
        </div>
        <button
          onClick={goToImportPage}
          className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
        >
          Retry
        </button>
      </div>
    </div>
  );
};

export default FailedComponent;
