import React from "react";
import { useShallow } from "zustand/shallow";
import { STEP_PAGES } from "../utils/Constants";
import LoadingView from "./LoadingView";
import ProductViewer from "./MenuReview/ProductViewer";
import { useProcessStore } from "../Store";
import UploadImage from "./FileUploader/UploadImageAndExtractData";
import ImageEditor from "./ImageEditor/ImageEditor";
import ResultComponent from "./Result/ResultComponent";

const MenuExtractor = () => {
  const { isLoading, currentStep } = useProcessStore(
    useShallow((state) => ({
      isLoading: state.isLoading,
      currentStep: state.currentStep,
    }))
  );

  return !isLoading ? (
    <div className="container mx-auto flex items-center justify-center h-screen w-screen">
      {currentStep === STEP_PAGES.MENU_REVIEW && <ProductViewer />}
      {currentStep === STEP_PAGES.IMAGE_UPLOADER && <UploadImage />}
      {currentStep === STEP_PAGES.IMAGE_EDITOR && <ImageEditor />}
      {currentStep === STEP_PAGES.RESULT && <ResultComponent />}
    </div>
  ) : (
    <LoadingView />
  );
};

export default MenuExtractor;
