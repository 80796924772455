import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import ProductItem from "./Items/ProductItem";
import StoreProductItem from "./StoreProductItem";
const StoreCategoryItemDialog = ({
  isOpen,
  setIsOpen,
  category,
  overwriteStore,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50 "
    >
      <DialogBackdrop className="fixed inset-0 bg-black/60" />

      <div className="fixed inset-0 w-screen overflow-y-auto p-4  ">
        <div className="flex min-h-full items-center justify-center">
          <DialogPanel className="max-w-xl space-y-4 border bg-white p-4">
            <DialogTitle className="font-bold text-center">
              Category Products
            </DialogTitle>
            <div className="max-h-[80vh] overflow-y-auto">
              {category?.importedProducts?.map((product, index) => {
                return <StoreProductItem product={product} key={product._id} />;
              })}
            </div>
            <div className="flex gap-4 mx-auto">
              <Button
                className="mx-auto bg-slate-200 rounded-sm px-8 py-2 text-gray-700 font-medium"
                onClick={() => setIsOpen(null)}
              >
                Okay
              </Button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
StoreCategoryItemDialog.propTypes = {
  overwriteStore: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  category: PropTypes.shape({
    category: PropTypes.string.isRequired,
    importedProducts: PropTypes.array,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
        description: PropTypes.array.isRequired,
      }).isRequired
    ),
    imported: PropTypes.bool,
  }).isRequired,
};
export default StoreCategoryItemDialog;
