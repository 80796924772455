import React from "react";
import { useProcessStore } from "../../Store";
import { useShallow } from "zustand/shallow";
import FailedComponent from "./FailedComponent";
import SuccessComponent from "./SuccessComponent";

const ResultComponent = () => {
  const { error } = useProcessStore(
    useShallow((state) => ({
      error: state.error,
    }))
  );
  return <div>{error ? <FailedComponent /> : <SuccessComponent />}</div>;
};

export default ResultComponent;
