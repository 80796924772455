import React from "react";
import PropTypes from "prop-types";

const StoreProductItem = ({ product }) => {
  const productPrice = product?.units?.[0]?.floatValue ?? 0;
  const addons = product?.units?.[0].addOns ?? [];
  return (
    <div className="max-w-xl rounded overflow-hidden shadow-lg p-4 my-2 bg-white border border-gray-200">
      <h2 className="text-xl font-bold mb-2 text-gray-800">
        {product?.pName?.en ?? ""}{" "}
        <span className="mx-2 text-rose-500">€{productPrice}</span>
      </h2>
      <p className="text-gray-600 text-sm mb-4">
        {product.sDescription?.en ?? ""}
      </p>
      {addons.length > 0 && <h5 className="font-bold">Addons</h5>}
      {addons.map((addon, index) => {
        return (
          <div key={product._id} className="flex items-center justify-between">
            <ul key={addon.unitId} className="list-disc list-inside">
              {addon?.addOns?.map((addon, index) => {
                return (
                  <li key={addon.id} className="text-gray-600">
                    {addon.name.en} - €{addon.price}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

StoreProductItem.propTypes = {
  product: PropTypes.shape({
    detailDescription: PropTypes.shape({
      nl: PropTypes.string,
      en: PropTypes.string,
    }),
    pName: PropTypes.shape({
      nl: PropTypes.string,
      en: PropTypes.string,
    }).isRequired,
    sDescription: PropTypes.shape({
      nl: PropTypes.string,
      en: PropTypes.string,
    }).isRequired,
    units: PropTypes.array.isRequired,
    _id: PropTypes.string.isRequired,
  }).isRequired,
};

export default StoreProductItem;
