import React from "react";
import { Button } from "@headlessui/react";

const SuccessButton = ({ onClick, label, classNames = "" }) => {
  return (
    <Button
      onClick={onClick}
      type="button"
      className={`relative w-75 my-3 lg:my-9 bg-emerald-700 z-30 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-500 border border-gray-200 rounded-md cursor-pointer group ease hover:!bg-emerald-800 active:!bg-emerald-700 ${classNames}`}
    >
      <span className="absolute bottom-0 left-0 h-full -ml-2">
        <svg
          viewBox="0 0 487 487"
          className="w-auto h-full opacity-60 object-stretch"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 .3c67 2.1 134.1 4.3 186.3 37 52.2 32.7 89.6 95.8 112.8 150.6 23.2 54.8 32.3 101.4 61.2 149.9 28.9 48.4 77.7 98.8 126.4 149.2H0V.3z"
            fill="#FFF"
            fillRule="nonzero"
            fillOpacity=".1"
          />
        </svg>
      </span>
      <span className="absolute top-0 right-0 w-12 h-full -mr-3">
        <svg
          viewBox="0 0 487 487"
          className="object-cover opacity-60 w-full h-full"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M487 486.7c-66.1-3.6-132.3-7.3-186.3-37s-95.9-85.3-126.2-137.2c-30.4-51.8-49.3-99.9-76.5-151.4C70.9 109.6 35.6 54.8.3 0H487v486.7z"
            fill="#FFF"
            fillRule="nonzero"
            fillOpacity=".1"
          />
        </svg>
      </span>
      <span className="relative">
        {label}
        {/* Continue With{" "}
        {croppedImages.length > 0 ? "Cropped Images" : "Orginal Image"} */}
      </span>
    </Button>
  );
};

export default SuccessButton;
