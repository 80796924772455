import React from "react";
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
const WarningDialog = ({ isOpen, setIsOpen, uploadImages }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/60" />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-lg space-y-4 border bg-white p-8">
          <DialogTitle className="font-bold">Upload Image</DialogTitle>
          <Description>
            Are you sure you want to continue with orginal image?
          </Description>
          <p>You may enter duplicate information</p>
          <div className="flex gap-4">
            <button onClick={() => setIsOpen(false)}>Cancel</button>
            <button onClick={uploadImages}>Continue</button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default WarningDialog;
