import React from "react";
import { useProcessStore } from "../../Store";
import { useShallow } from "zustand/shallow";
import { STEP_PAGES, ENV_CONFIG } from "../../utils/Constants";

const SuccessComponent = () => {
  const { goToStep } = useProcessStore(
    useShallow((state) => ({
      goToStep: state.goToStep,
    }))
  );
  const goToImportPage = () => {
    goToStep(STEP_PAGES.IMAGE_UPLOADER);
  };
  const goToStorePage = () => {
    window.location.href = ENV_CONFIG.REDIRECT_URL;
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm text-center">
        <div className="flex justify-center items-center bg-green-100 text-green-500 w-12 h-12 rounded-full mx-auto mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="{2}"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Success!</h2>
        <p className="text-gray-600 mb-6">
          Your process was successfully completed.
        </p>
        <div className="flex flex-row justify-around">
          <button
            onClick={goToImportPage}
            className="bg-gray-400 hover:bg-gray-600 text-white font-medium py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
          >
            Back
          </button>
          <button
            onClick={goToStorePage}
            className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
          >
            Explore Your Store
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessComponent;
