import React from "react";
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
const DiscardChanges = ({ isOpen, setIsOpen, backButtonConfirm }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/60" />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-lg space-y-4 border bg-white p-8">
          <DialogTitle className="font-bold">Are you sure?</DialogTitle>
          <Description>You will lose all cropping you have made?</Description>
          <div className="flex gap-4">
            <button onClick={() => setIsOpen(false)}>Cancel</button>
            <button onClick={backButtonConfirm}>Continue</button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default DiscardChanges;
